<template>
  <div>
    <b-card>
      <!-- Other stuff -->
      <b-overlay :show="show">
        <b-form>
          <b-row
            v-for="(v, k) in Setting"
            :key="k"
            no-gutters>
            <b-col cols="8">
              <b-form-group
                :label="v.name"
                :label-for="v.name"
                label-cols-md="4"
              >
                <b-form-input
                  :id="v.name"
                  ref="mylink"
                  :value="url + v.type + '/' + v.token"
                  type="text"
                  rel="noopener noreferrer"
                  placeholder="Token donate alert address"
                />
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-button
                variant="primary"
                @click="copyUrl(url + v.type + '/' + v.token)"
              ><feather-icon icon="CopyIcon" size="12" />
              Copy</b-button>
              <b-button
                variant="danger"
                @click="generationURL($event, v.type, k)"
              >
              <b-spinner
                v-if="v.loading"
                small
              /> <feather-icon icon="RefreshCwIcon" size="12" />
              đổi url mới</b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-overlay>
    </b-card>
  </div>
</template>
<script>
import { BCol, BRow, BForm, BFormGroup, BFormInput, BSpinner, BCard, BOverlay, BButton } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BCol,
    BRow,
    BForm,
    BFormInput,
    BButton,
    BFormGroup,
    BSpinner,
    BCard,
    BOverlay,
  },
  data() {
    return {
      show: true,
      loading_generate: [],
      url: `${process.env.VUE_APP_HOME_URL}/screens/`,
    }
  },
  computed: {
    Setting() {
      return this.$store.getters['donate/topData'].json
    },
    loadingTop() {
      return this.$store.getters['donate/topData'].loading
    },
  },
  created() {
    if (this.loadingTop === false) {
      useJwt.getWidget('top')
        .then(({ data }) => {
          this.$store.commit('donate/GET_TOP', data)
          this.show = false
        }).catch(() => {
          // 404
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error!',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        })
    } else {
      this.show = false
    }
  },
  methods: {
    copyUrl(e) {
      const el = document.createElement('textarea')
      el.value = e
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      const selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      if (selected) {
        document.getSelection().removeAllRanges()
        document.getSelection().addRange(selected)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Text copied',
            icon: 'BellIcon',
          },
        })
      }
    },
    generationURL(e, type, k) {
      e.preventDefault()
      this.Setting[k].loading = true
      useJwt.generatorWidgetToken(type).then(({ data }) => {
        this.Setting[k].loading = false
        if (data.result === true) {
          this.Setting[k].token = data.data
          //  this.$store.commit('donate/GENERATOR_TOKEN', {
          //    type: 'top',
          //   payload: data.data,
          //   k: k,
          // })
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Generated token success!',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: data.error,
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
  },
}
</script>
